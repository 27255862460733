<template>
    <div>
        <nav-single></nav-single>

        <div class="body-single">
            <div class="body-single__body">
                <div v-if="singleSpot" class="local-information">
                    <div class="info-cards">
                        <div v-if="hasInformation(singleSpot.shore_surface)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/shore-surface.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Shore Surface
                                    <div class="title__sub">
                                        {{ singleSpot.shore_surface_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.shore_surface }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.paddling)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/paddling.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Paddling
                                    <div class="title__sub">
                                        {{ singleSpot.paddling_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.paddling }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.jumping_or_diving)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/jumping-diving.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Jumping/diving
                                    <div class="title__sub">
                                        {{ singleSpot.jumping_or_diving_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.jumping_or_diving }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.open_for_beginners)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/open-water-beginners.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Open water beginners
                                    <div class="title__sub">
                                        {{ singleSpot.open_for_beginners_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.open_for_beginners }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.accessible)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/accessible.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Accessible
                                    <div class="title__sub">
                                        {{ singleSpot.accessible_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.accessible }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.car_park)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/car-park.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Car Park
                                    <div class="title__sub">
                                        {{ singleSpot.car_park_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.car_park }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.lifeguard_during_summer)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/lifeguard.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Lifeguard
                                    <div class="title__sub">
                                        {{ singleSpot.lifeguard_during_summer_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.lifeguard_during_summer }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.toilets)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/toilets.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Toilets
                                    <div class="title__sub">
                                        {{ singleSpot.toilets_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.toilets }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.changing_facilities)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/changing-facilities.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Changing facilities
                                    <div class="title__sub">
                                        {{ singleSpot.changing_facilities_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.changing_facilities }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.family_friendly)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/family-friendly.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Family friendly
                                    <div class="title__sub">
                                        {{ singleSpot.family_friendly_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.family_friendly }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.blue_flag_beach)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/blue-flag-beach.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Blue flag beach?
                                    <div class="title__sub">
                                        {{ singleSpot.blue_flag_beach_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.blue_flag_beach }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.water_tested_regularly)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/water-tested-regularly.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Water tested regularly
                                    <div class="title__sub">
                                        {{ singleSpot.water_tested_regularly_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.water_tested_regularly }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.swim_buoys)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/swim-buoys.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Swim buoys
                                    <div class="title__sub">
                                        {{ singleSpot.swim_buoys_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.swim_buoys }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.shop_or_cafe)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/shop-or-cafe.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Shop or café
                                    <div class="title__sub">
                                        {{ singleSpot.shop_or_cafe_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.shop_or_cafe }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.accessible_by_public_transport)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/accessible-public-transport.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Accessible by public transport
                                    <div class="title__sub">
                                        {{ singleSpot.accessible_by_public_transport_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.accessible_by_public_transport }}
                                </div>
                            </div>
                        </div>
                        <div v-if="hasInformation(singleSpot.club_or_regular_swimming_groups)" class="card">
                            <div class="icon">
                                <img src="@/assets/img/local-information-icons/club.svg">
                            </div>
                            <div class="card__description">
                                <div class="title">
                                    Club
                                    <div class="title__sub">
                                        {{ singleSpot.club_or_regular_swimming_groups_note }}
                                    </div>
                                </div>
                                <div class="value">
                                    {{ singleSpot.club_or_regular_swimming_groups }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="description-cards" v-if="hasDescriptionCards">
                        <div class="shell">
                            <div class="description-cards__head" v-if="images && images.length">
                                <carousel :images="images"></carousel>
                            </div>
                            <div class="description-cards__body">
                                <div v-if="hasInformation(singleSpot.general_information)" class="card">
                                    <div class="icon">
                                        <img src="@/assets/img/local-information-icons/general-information.svg">
                                    </div>
                                    <div class="card__description">
                                        <div class="title">General Information</div>
                                        <div class="value">{{ singleSpot.general_information }}</div>
                                    </div>
                                </div>
                                <div v-if="hasInformation(singleSpot.contact_for_local_swimming_group)" class="card card__primary">
                                    <div class="icon">
                                        <img src="@/assets/img/local-information-icons/contact.svg">
                                    </div>
                                    <div class="card__description">
                                        <div class="title">
                                            Contact
                                            <div class="title__sub">
                                                (for local swimming group)
                                            </div>
                                        </div>
                                        <div class="value">
                                            <a :href="`mailto:${singleSpot.contact_for_local_swimming_group}`">
                                                {{ singleSpot.contact_for_local_swimming_group }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="hasInformation(singleSpot.local_tip)" class="card card__secondary">
                                    <div class="icon">
                                        <img src="@/assets/img/local-information-icons/local-tip.svg">
                                    </div>
                                    <div class="card__description">
                                        <div class="title">Local Tip</div>
                                        <div class="value">{{ singleSpot.local_tip }}</div>
                                    </div>
                                </div>
                                <div v-if="hasInformation(singleSpot.contact_information)" class="card">
                                    <div class="icon">
                                        <img src="@/assets/img/local-information-icons/general-information.svg">
                                    </div>
                                    <div class="card__description">
                                        <div class="title">Contact Information</div>
                                        <div class="value">{{ singleSpot.contact_information }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavSingle from '@/components/Nav/NavSingle.vue'
import Carousel from '@/components/Carousel'
export default {
    inject: ['swimSpots'],
    components: {
        NavSingle,
        Carousel
    },
    data() {
        return {
            singleId: this.$route.params.id,
            singleSpot: this.swimSpots.spots[this.$route.params.id],
        };
    },
    methods: {
        hasInformation(data) {
            return data && data !== 'No Information';
        },
    },
    computed: {
        hasDescriptionCards() {
            return !(!this.singleSpot.general_information &&
            !this.singleSpot.contact_for_local_swimming_group &&
            !this.singleSpot.local_tip &&
            !this.singleSpot.contact_information);
        },
        images() {
            return this.singleSpot.images || [];
        }
    },
    watch: {
        swimSpots: {
            handler(swimSpots) {
                this.singleSpot = swimSpots.spots[this.singleId];
            },
            deep: true,
        },
    }
}
</script>

<style>
.body-single {
    padding: 0 120px;
}
.card {
    position: relative;
}
.card,
.local-information {
    display: flex;
}
.local-information {
    max-width: 100%;
}
.local-information,
.local-information .info-cards{
    flex: 1;
}
.local-information .info-cards {
    display: grid;
}
.local-information .info-cards .card {
    min-height: 55px;
    padding: 19px 38px 23px 29px;
    justify-content: space-between;
}
.local-information .info-cards .card .icon {
    display: flex;
    align-items: center;
}
.local-information .info-cards .card .card__description {
    text-align: right;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.local-information .card .card__description .title {
    line-height: 16px;
    font-size: 14px;
    color: #92909A;
}
.local-information .card .card__description .title .title__sub {
    font-size: 12px;
}

.local-information .info-cards .card .card__description .value {
    font-family: "DM Sans", "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #2A2733;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}


/** ---- Description Cards ---- **/
.local-information .description-cards > .shell {
    text-align: left;
    flex: 1;
    margin-left: 20px;
}
.local-information .description-cards .card {
    padding: 27px 27px 18px;
}
.local-information .description-cards .card .card__description {
    padding-top: 8px;
    padding-left: 20px;
}

.local-information .description-cards .card .card__description .value {
    margin-top: 11px;
    line-height: 32px;
}

.local-information .description-cards .card.card__primary {
    padding: 11px 27px 15px;
}
.local-information .description-cards .card.card__primary .icon {
    padding-top: 18px;
}
.local-information .description-cards .card.card__primary .card__description .title {
    margin-left: 2px;
}
.local-information .description-cards .card.card__primary .card__description .value a {
    font-size: 18px;
    font-weight: bold;
    color: #009FAE;
    margin-left: 3px;
    margin-top: -2px;
    letter-spacing: -0.2px;
}

.local-information .description-cards .description-cards__head {
    margin-bottom: 20px;
}

.local-information .description-cards .card.card__secondary {
    padding: 9px 12px 18px 31px;
}
.local-information .description-cards .card.card__secondary .icon {
    padding-top: 17px;
}
.local-information .description-cards .card.card__secondary .card__description {
    margin-left: 6px;
}
.local-information .description-cards .card.card__secondary .card__description .value {
    font-size: 15px;
    letter-spacing: 0.06px;
}
@media (min-width:320px)  {
    .body-single {
        padding: 0 30px 0 30px;
    }
    .local-information {
        flex-direction: column-reverse;
    }
    .local-information .description-cards > .shell {
        margin-left: 0;
    }
    .local-information .description-cards .card {
        border: 0;
    }

    .local-information .info-cards,
    .local-information .description-cards .description-cards__body {
        border: 2px solid #F3F3F3;
        border-radius: 10px;
    }

    .local-information .description-cards > .shell {
        margin-bottom: 17px;
    }
    .local-information .info-cards {
        grid-template-columns: repeat(1, minmax(341px, 1fr));
    }
    .local-information .card + .card::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 88%;
        height: 2px;
        background: #F3F3F3;
        transform: translate(-50%);
    }
}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  {
    .body-single {
        padding: 0 120px;
    }
}
@media (min-width:1025px) {
    .local-information .card + .card::after {
        content: none;
    }
    .local-information {
        flex-direction: row;
    }
    .local-information .description-cards > .shell {
        margin-left: 20px;
        margin-bottom: 0;
    }
    .local-information .description-cards .card + .card {
        margin-top: 21px;
    }

    .local-information .info-cards,
    .local-information .description-cards .description-cards__body {
        border: 0;
        border-radius: 0;
    }
    .local-information .info-cards {
        grid-template-columns: repeat(auto-fit, minmax(341px, 1fr));
        grid-gap: 20px;
    }

    .local-information .info-cards .card,
    .local-information .description-cards .card {
        border: 2px solid #F3F3F3;
        border-radius: 10px;
    }
    .local-information .description-cards,
    .local-information .info-cards {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

</style>
