<template>
    <div class="carousel">
        <swiper :style="{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    '--swiper-navigation-size' : '23px',
                }"
                :spaceBetween="10" :loop="true" :navigation="true" :thumbs="{ swiper: thumbsSwiper }" class="main-slider">
            <swiper-slide v-for="(imageURL, index) in images" :key="imageURL">
                <img :src="imageURL" :alt="'Image ' + index + 1"/>
            </swiper-slide>
        </swiper>
        <swiper v-if="images.length > 1"
                :breakpoints="{
                    320: {
                        slidesPerView: 2,
                    },
                    400: {
                        slidesPerView: 3,
                    },
                    600: {
                        slidesPerView: 4,
                    },
                    1025: {
                        slidesPerView: 3,
                    },
                    1250: {
                        slidesPerView: 4,
                    }
                }"
                @swiper="setThumbsSwiper" :loop="true" :spaceBetween="20" :slidesPerView="4" :freeMode="true"
                :watchSlidesVisibility="true" :watchSlidesProgress="true" class="thumbs-slider">
            <swiper-slide v-for="(imageURL, index) in images" :key="'thumb--' + imageURL">
                <img :src="imageURL" :alt="'Image ' + index + 1"/>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/swiper.scss';
    import '@/assets/scss/swiper/controls.scss'
    import '@/assets/scss/swiper/main.scss'

    // import Swiper core and required modules
    import SwiperCore, {
        Navigation,Thumbs
    } from 'swiper/core';

    // install Swiper modules
    SwiperCore.use([Navigation,Thumbs]);
    export default {
        props: {
            images: {
                type: Array,
                required: true,
            },
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                thumbsSwiper: null
            };
        },
        methods: {
            setThumbsSwiper(swiper) {
                swiper.loopDestroy();
                swiper.loopCreate();
                this.thumbsSwiper = swiper;
            },
        }
    }
</script>
